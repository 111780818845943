@use "./../../styles/variables.scss" as *;

.button-hero-container {
  position: relative;
  width: 100%;
  user-select: none;
}

.button-hero-parent {
  transition: 0.3s transform ease-out;
  cursor: pointer;

  &.is3dButton {
    &:hover,
    &:active,
    &:focus-visible {
      transform: translate(8px, 8px);
    }

    .button-hero::after {
      z-index: -2;
    }
  }

  &.isPulsing {
    animation: push-button 3s ease-out infinite;
    animation-delay: 2s;
    &:hover,
    &:active,
    &:focus-visible {
      animation: none;
      transform: translate(8px, 8px);
    }
  }
}

.button-hero {
  transition: 0.3s background-color ease-out, 0.3s color ease-out, 0.3s filter ease-out;

  &.glow-soft,
  &.glow-hard {
    filter: drop-shadow(0px 0px 1px rgba($white, 0));
  }

  white-space: nowrap;
  font-weight: 700;
  color: $primary;
  padding: 1.4rem 1rem;
  border-radius: 12px;

  font-size: 1.4rem;
  z-index: 1;

  &:hover,
  &:active,
  &:focus-visible {
    &.glow-hard {
      filter: drop-shadow(0px 0px 80px rgba($white, 1));
    }
    &.glow-soft {
      filter: drop-shadow(0px 0px 80px rgba($white, 0.7));
    }
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    border-radius: 12px;
    z-index: 0;
    // clip-path: polygon(0% 20%, 95% 5%, 92% 98%, 3% 90%);
    transition: 0.3s background-color ease-out, 0.3s color ease-out, 0.3s filter ease-out;
  }
}

.button-thin {
  .button-hero {
    padding: 0.8rem 0.8rem;
    font-size: clamp(14px, 2vw, 1em);
  }
}

.button-hero-decoy {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  // clip-path: polygon(0% 20%, 95% 5%, 92% 98%, 3% 90%);
  border-radius: 12px;
  background-color: darken($primary, 5);
  transform: translate(8px, 8px);
  z-index: 0;
}

.button-primary-white {
  .button-hero-decoy {
    background-color: darken($white, 5);
  }

  .button-hero {
    color: $white;
    &::after {
      background-color: $primary;
    }
  }

  .button-hero-parent {
    &:hover,
    &:active,
    &:focus-visible {
      .button-hero {
        &.glow-hard {
          filter: drop-shadow(0px 0px 80px rgba($white, 1));
        }
        &.glow-soft {
          filter: drop-shadow(0px 0px 80px rgba($white, 0.7));
        }
        &::after {
          background-color: $white;
        }
        color: $primary;
      }
    }
  }
}

.button-primary-black {
  .button-hero-decoy {
    // background-color: darken($primary, 15);
    background-color: $black;
  }

  .button-hero {
    color: $white;
    &::after {
      background-color: $primary;
    }

    // position: relative;
    // &::before {
    //   transition: all 0.3s ease-out;

    //   content: "";
    //   z-index: -1;
    //   position: absolute;
    //   width: 100%;
    //   left: 0%;
    //   height: 100%;
    //   top: 0%;
    //   clip-path: polygon(5% 20%, 95% 5%, 92% 98%, 8% 90%);
    //   background-color: $black;
    //   opacity: 0;
    // }
  }
  .button-hero-parent {
    &:hover,
    &:active,
    &:focus-visible {
      .button-hero {
        &.glow-hard {
          filter: drop-shadow(0px 0px 80px rgba($black, 0.8));
        }
        &.glow-soft {
          filter: drop-shadow(0px 0px 50px rgba($black, 0.6));
        }
        &::after {
          background-color: $black;
        }
        color: $white;

        // &::before {
        //   width: calc(100% - 1rem);
        //   left: calc(0% + 0.5rem);
        //   height: calc(100% - 1rem);
        //   top: calc(0% + 0.5rem);
        //   opacity: 1;
        // }
      }
    }
  }
}

.button-white-black {
  .button-hero-decoy {
    // background-color: darken($white, 50);
    background-color: $black;
  }
  .button-hero {
    color: $primary;
    &::after {
      background-color: $white;
    }

    &:hover,
    &:active,
    &:focus-visible {
      &.glow-hard {
        filter: drop-shadow(0px 0px 80px rgba($black, 0.8));
      }
      &.glow-soft {
        filter: drop-shadow(0px 0px 50px rgba($black, 0.6));
      }
      &::after {
        background-color: $black;
      }
      color: $white;
    }
  }
}

.button-black-white {
  .button-hero-decoy {
    background-color: darken($black, 5);
  }
  .button-hero {
    color: $white;
    &::after {
      background-color: $black;
    }

    &:hover,
    &:active,
    &:focus-visible {
      &.glow-hard {
        filter: drop-shadow(0px 0px 80px rgba($white, 1));
      }
      &.glow-soft {
        filter: drop-shadow(0px 0px 80px rgba($white, 0.7));
      }
      &::after {
        background-color: $white;
      }
      color: $primary;
    }
  }
}

@keyframes push-button {
  10% {
    transform: translate(8px, 8px);
  }
  20% {
    transform: translate(0px, 0px);
  }
  30% {
    transform: translate(8px, 8px);
  }
  40% {
    transform: translate(0px, 0px);
  }
}

.outline-on-hover {
  .button-hero {
    color: $white;
    &::after {
      background-color: $primary;
    }
    // background-color: $primary;
    position: relative;
    &::before {
      transition: all 0.3s ease-out;

      content: "";
      z-index: -1;
      position: absolute;
      width: 100%;
      left: 0%;
      height: 100%;
      top: 0%;
      // clip-path: polygon(0% 20%, 95% 5%, 92% 98%, 3% 90%);
      background-color: $black;
      opacity: 0;
    }
  }
  &.button-hero-parent {
    &:hover,
    &:active,
    &:focus-visible {
      .button-hero {
        &::after {
          background-color: $white;
        }
        color: $white;

        &::before {
          width: calc(100% - 1rem);
          left: calc(0% + 0.5rem);
          height: calc(100% - 1rem);
          top: calc(0% + 0.5rem);
          opacity: 1;
        }
      }
    }
  }
}
