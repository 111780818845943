@use "./../../styles/variables.scss" as *;

.nav-menu-button-container {
  transition: all 0.2s ease-out;

  .nav-menu-button {
    margin: 0.3em;
    color: $white;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    user-select: none;
    transition: 0.2s ease-out;
    text-align: center;
    // border-radius: 4px;
    padding: 0.5rem 1rem;
  }

  &:hover .nav-menu-button,
  &:focus-visible .nav-menu-button {
    color: $primary;
    outline: none;
  }

  &.active .nav-menu-button {
    // color: $white;
    color: $primary;
    // background-color: $accent;
    border-bottom: 2px solid $primary;
  }
}
