@use "./../../styles/variables.scss" as *;

.nav-menu-bar-container {
  width: 100%;
  height: $nav-menu-size;
}

.nav-menu-bar {
  position: fixed;
  width: 100%;
  top: 0;
  height: $nav-menu-size;
  background-color: rgba($gray, 0.8);
  backdrop-filter: blur(8px);
  z-index: 10;
  box-shadow: 0px 4px 24px rgb(0 0 0 / 50%);

  .brand-identity {
    display: flex;
    position: absolute;
    left: 10vw;
    @media (max-width: map-get($grid-breakpoints, lg)) {
      transform: scale(0.7);
      transform-origin: left;
      .logo-slogan {
        margin-top: -3px;
        height: calc($nav-logo-size / 2 + 3px - 1em);
      }
    }
  }

  .logo {
    margin-top: 0px;
    height: $nav-logo-size;
    pointer-events: none;
    user-select: none;
  }
  .logo-slogan {
    white-space: nowrap;
    padding-left: calc($nav-logo-size / 2 + 0.5em);
    padding-top: 1em;
    transform: translate(calc($nav-logo-size / -2), $nav-menu-size);
    font-weight: 700;
    color: $white;
    height: calc($nav-logo-size / 2 - 1em);
    z-index: -1;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.6), transparent);
    clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 0% 100%);
    backdrop-filter: blur(2px);
    padding-right: 12px;
  }

  .nav-buttons-container {
    align-items: center;
    justify-content: flex-end !important;
    display: flex !important;
    width: 100%;
    height: $nav-menu-size;
    gap: 0rem;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      transform: scale(0.7);
      transform-origin: center;
    }
    @media (max-width: map-get($grid-breakpoints, md)) {
      display: none !important;
    }
  }

  .burger-menu-container {
    position: absolute;
    cursor: pointer;
    right: 5vw;
    padding: 0px 2em;
    height: 100%;
    display: flex;
    align-items: center;

    @media (min-width: map-get($grid-breakpoints, md)) {
      display: none !important;
    }
  }

  .mobile-menu {
    transition: 0.3s all ease-in-out;
    display: flex;
    font-size: 24px;
    right: 12px;
    top: 12px;
    flex-direction: column;
    text-align: end;
    z-index: -1;
    position: fixed;
    gap: 2em;
    display: none;

    &.visible {
      z-index: 2;
    }

    &.active {
      position: fixed;

      .menu-toggler {
        position: fixed;
        top: 0px;
        right: 0px;
        cursor: pointer;
        padding: 20px;
      }
    }

    &.mobile-active {
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: $primary;
      align-content: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      display: flex;

      .menu-item {
        color: $white;
        display: block;

        &.active,
        &:hover,
        &:focus-visible {
          color: $black !important;
          outline: none;
        }
      }
    }
  }
}
