@use "variables.scss" as *;

:root {
  @each $key, $breakpoint in $grid-breakpoints {
    --bs-breakpoint-#{$key}: #{(map-get($container-max-widths, $key))} !important;
  }
}

//
// Typography
//

html,
body {
  font-family: $base-font-family;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $font-line-height-base;
  font-kerning: auto;
}

@each $fontKey, $fontSize in $heading-sizes {
  .#{$fontKey} {
    font-size: #{$fontSize};
    font-weight: map-get($heading-weights, $fontKey);
    font-family: map-get($heading-font-families, $fontKey);
    line-height: map-get($heading-line-heights, $fontKey);
  }
}
@each $breakpointKey, $breakpoint in $grid-breakpoints {
  @each $fontKey, $fontSize in $heading-sizes {
    .#{$fontKey}-#{$breakpointKey} {
      @media (min-width: $breakpoint) {
        font-size: #{$fontSize};
        font-weight: map-get($heading-weights, $fontKey);
        font-family: map-get($heading-font-families, $fontKey);
        line-height: map-get($heading-line-heights, $fontKey);
      }
    }
  }
}

.font-semi-bold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-black {
  font-weight: 900;
}

.color-primary {
  color: $primary;
}
.color-secondary {
  color: $secondary;
}
.color-accent {
  color: $accent;
}
.color-white {
  color: $white;
}
.color-black {
  color: $black;
}

@each $key, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    .font-semi-bold-#{$key} {
      font-weight: 600;
    }
    .font-bold-#{$key} {
      font-weight: 700;
    }
    .font-black-#{$key} {
      font-weight: 900;
    }
  }
}

.container {
  margin: auto;
  @each $key, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
      max-width: map-get($container-max-widths, $key);
    }
  }
}

.big-container {
  margin: auto;
  @each $key, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
      max-width: calc(map-get($container-max-widths, $key) + 30vw);
    }
  }
}

.small-container {
  margin: auto;
  @each $key, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
      max-width: calc(map-get($container-max-widths, $key) - 8vw);
    }
  }
}

.container {
  @media (max-width: map-get($grid-breakpoints, md)) {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
}

.md-padded-container {
  @media (max-width: map-get($grid-breakpoints, md)) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@for $i from 1 through 7 {
  $size-increment: 0.8rem;

  .gap-#{$i} {
    gap: calc($size-increment * $i * $i/3) !important;
  }
}

@for $i from 0 through 7 {
  $size-increment: 0.8rem;

  @each $key, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
      .m-#{$key}-#{$i} {
        margin: calc($size-increment * $i * $i/3) !important;
      }
      .mt-#{$key}-#{$i} {
        margin-top: calc($size-increment * $i * $i/3) !important;
      }
      .ml-#{$key}-#{$i} {
        margin-left: calc($size-increment * $i * $i/3) !important;
      }
      .mr-#{$key}-#{$i} {
        margin-right: calc($size-increment * $i * $i/3) !important;
      }
      .mb-#{$key}-#{$i} {
        margin-bottom: calc($size-increment * $i * $i/3) !important;
      }

      .p-#{$key}-#{$i} {
        padding: calc($size-increment * $i * $i/3) !important;
      }
      .pt-#{$key}-#{$i} {
        padding-top: calc($size-increment * $i * $i/3) !important;
      }
      .pl-#{$key}-#{$i} {
        padding-left: calc($size-increment * $i * $i/3) !important;
      }
      .pr-#{$key}-#{$i} {
        padding-right: calc($size-increment * $i * $i/3) !important;
      }
      .pb-#{$key}-#{$i} {
        padding-bottom: calc($size-increment * $i * $i/3) !important;
      }
    }
  }

  .m-#{$i} {
    margin: calc($size-increment * $i * $i/3) !important;
  }
  .mt-#{$i} {
    margin-top: calc($size-increment * $i * $i/3) !important;
  }
  .ml-#{$i} {
    margin-left: calc($size-increment * $i * $i/3) !important;
  }
  .mr-#{$i} {
    margin-right: calc($size-increment * $i * $i/3) !important;
  }
  .mb-#{$i} {
    margin-bottom: calc($size-increment * $i * $i/3) !important;
  }

  .p-#{$i} {
    padding: calc($size-increment * $i * $i/3) !important;
  }
  .pt-#{$i} {
    padding-top: calc($size-increment * $i * $i/3) !important;
  }
  .pl-#{$i} {
    padding-left: calc($size-increment * $i * $i/3) !important;
  }
  .pr-#{$i} {
    padding-right: calc($size-increment * $i * $i/3) !important;
  }
  .pb-#{$i} {
    padding-bottom: calc($size-increment * $i * $i/3) !important;
  }
}

.height-30px {
  height: 30px;
}

.w-100 {
  width: 100%;
}

.w-100vw {
  height: 100vw;
}

.max-w-100vw {
  max-width: 100vw;
}

.h-100 {
  height: 100%;
}

.h-100vh {
  height: 100vh;
}

.max-h-100vh {
  max-height: 100vh;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

@each $key, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    .text-#{$key}-center {
      text-align: center;
    }
    .text-#{$key}-left {
      text-align: left;
    }
    .text-#{$key}-right {
      text-align: right;
    }
  }
}

.text-white {
  color: $white !important;
}

.text-black {
  color: $black !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.overflow-hidden {
  overflow: hidden;
}

.text-nowrap {
  white-space: nowrap;
}

.p-relative {
  position: relative !important;
}

.d-flex {
  display: flex !important;
}

.d-none {
  display: none !important;
}

@each $key, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    .d-#{$key}-flex {
      display: flex !important;
    }
    .d-#{$key}-block {
      display: block !important;
    }
    .d-#{$key}-none {
      display: none !important;
    }
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row {
  flex-direction: row;
}

.responsive-rows {
  flex-wrap: wrap;
  gap: 1rem;

  @media (max-width: map-get($grid-breakpoints, md)) {
    .flex-1:nth-child(2n) {
      flex-basis: 100%;
    }
  }
}

.flex-1 {
  flex: 1;
}

.min-width-100 {
  min-width: 100px;
}

.justify-content-evenly {
  justify-content: space-evenly;
}
.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.rotate-90 {
  transform: rotate(90deg);
}

.no-decoration {
  text-decoration: none;
}

.no-selection {
  user-select: none;
}

.avoid-wrap {
  display: inline-block;
}

.customInput {
  position: relative;
  box-sizing: border-box;
  background: $white;
  border-radius: 4px;
  border: 2px solid rgba($primary, 0.5);
  color: $black;
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  line-height: 100%;
  transition: all ease-out 0.4s;
  outline: transparent !important;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: 2px solid lighten($dark, 0%);
  }
  &:hover {
    background: lighten($primary, 55%);
    border: 2px solid lighten($dark, 20%);
  }

  &:active,
  &:focus,
  &:focus-visible,
  &:focus-within {
    border: 2px solid lighten($dark, 1);
  }
}

.custom-input {
  padding: 1rem;
  border: 4px solid $white;
  transition: border ease-out 0.3s;
  outline: none;

  &:focus-visible,
  &:focus-within,
  &:active,
  &:focus {
    border: 4px solid $lt-gray;
  }

  &:hover {
    border: 4px solid $dark;
  }
}

a {
  text-decoration: none !important;
  cursor: pointer;
}

.white-link {
  color: $white;
  cursor: pointer;
  &:hover {
    color: $primary;
  }
}

.invisible {
  opacity: 0;
  position: absolute;
}

.invisible-no-interaction {
  opacity: 0;
  pointer-events: none;
  user-select: none;
}

.no-interaction {
  pointer-events: none;
  user-select: none;
}

.disabled {
  user-select: none !important;
  pointer-events: none !important;
  opacity: 0.5 !important;
}
