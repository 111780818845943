@use "./../../styles/variables.scss" as *;

.stakeholder-item {
  max-width: 22%;

  img {
    width: min(250px, 100%);
  }
  @media (max-width: map-get($grid-breakpoints, xxl)) {
    max-width: 50%;
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    max-width: 100%;
  }
}

.half-half {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > * {
    flex-grow: 1;
    width: 50%;
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    flex-wrap: wrap;

    .lg-under {
      position: absolute;
      right: 0;
      transform: translate(30%, 0%);
      opacity: 0.5;
      z-index: -1;
    }
  }
}

.image-divider {
  position: relative;
  z-index: 1;
  color: white;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: $primary;
  text-align: start;
  ::selection {
    color: $primary;
    background-color: $white;
  }

  .image-divider-content {
    max-width: 80%;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0%;
    height: 100%;
    top: 0%;
    z-index: -1;
    background: url("./../../assets/image-divider.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media (max-width: map-get($grid-breakpoints, xl)) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .image-divider-content {
      max-width: 100%;
    }
  }
}

@media (max-width: 1520px) {
  .split-on-lg > div {
    min-width: 30%;
  }
}
