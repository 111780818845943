@use "sass:meta";
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+4:opsz,wght@8..60,400;8..60,500;8..60,600;8..60,700;8..60,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Niconne&display=swap");

$primary: #ff9900;
$secondary: #5c80bc;
$accent: #ff5c00;
$hyper-accent: #0066ff;

$gray: #262626;
$dark: #1b1b1b;
$black: #080606;
$transparent-black: rgba(8, 6, 6, 0.8);
$lt-gray: #717171;
$white-gray: #b1b1b1;
$dirty-white: #fffcfc;
$white: #ffffff;

$success: #3bd100;
$confirm: #3bd100;
$alert: #ff7b00;
$danger: #d10000;

$hover-transition-duration: 0.2s;

$nav-logo-size: 120px;
$nav-menu-size: 70px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1520px,
);

$container-max-widths: (
  xs: 576px,
  sm: 576px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1420px,
);

// Typography

$font-size-base: 16x;
$font-weight-base: 400;
$font-line-height-base: 1;
$primary-font-family: "Source Serif 4", serif;
$secondary-font-family: "Source Sans 3", sans-serif;
$base-font-family: $secondary-font-family;

$heading-sizes: (
  font-hero: 62px,
  h0: 32px,
  h1: 32px,
  h2: 24px,
  h3: 20px,
  h4: 16px,
  h5: 12px,
  p1: $font-size-base,
  p2: 16px,
  font-label: 22px,
);

$heading-line-heights: (
  font-hero: 1.35,
  h0: 1.35,
  h1: 1.35,
  h2: 1.35,
  h3: 1.35,
  h4: 1.35,
  h5: 1.35,
  p1: 1,
  p2: 1,
  font-label: 1,
);

$heading-weights: (
  font-hero: 400,
  h0: 400,
  h1: 700,
  h2: 400,
  h3: 700,
  h4: 400,
  h5: 400,
  p1: 400,
  p2: 400,
  font-label: 400,
);

$heading-font-families: (
  font-hero: #{meta.inspect($primary-font-family)},
  h0: #{meta.inspect($primary-font-family)},
  h1: #{meta.inspect($secondary-font-family)},
  h2: #{meta.inspect($secondary-font-family)},
  h3: #{meta.inspect($secondary-font-family)},
  h4: #{meta.inspect($secondary-font-family)},
  h5: #{meta.inspect($secondary-font-family)},
  p1: #{meta.inspect($secondary-font-family)},
  p2: #{meta.inspect($secondary-font-family)},
  font-label: #{meta.inspect($secondary-font-family)},
);
