@use "./../../styles/variables.scss" as *;

.extra-options-selector-container {
  padding: 3rem;
  @media (max-width: map-get($grid-breakpoints, sm)) {
    padding: 0.8rem;

    .item-content {
      white-space: normal;
      max-width: 60%;
      min-width: min-content;
    }
    .item-dash {
      width: 0%;
      opacity: 0;
    }
  }
}

.price-area-container {
  display: flex;
  justify-content: flex-end;

  .price-area {
    width: calc(50% - 1.5rem);

    @media (max-width: map-get($grid-breakpoints, xl)) {
      width: 100%;
      left: 0%;
      transform: translate(0%, 0%);
    }
  }
}
