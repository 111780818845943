@use "./../../styles/variables.scss" as *;

.footer-container {
  position: relative;
  z-index: 2;
  color: $white;
  background-color: $dark;

  ::selection {
    color: $primary;
    background-color: $white;
  }

  .footer-logo {
    position: absolute;
    width: clamp(200px, 20vw, 300px);
    top: 0%;
    right: 0;
    transform: translate(0%, -100%);
    user-select: none;
  }

  @media (max-width: map-get($grid-breakpoints,md)) {
    .footer-logo {
      left: 50%;
      transform: translate(-50%, -100%);
    }
    padding-top: 180px !important;
  }
}
