@use "./../../styles/variables.scss" as *;

.gallery-container {
  width: 100%;
  position: relative;

  .thumbnail {
    max-width: 20vw;
    @media (max-width: map-get($grid-breakpoints, xl)) {
      max-width: 30vw;
      height: auto;
    }
  }
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: $primary !important;
}

.lg-react-element {
  display: grid;
  gap: 5px;
  grid-auto-flow: column;
  grid-template-rows: 1fr 1fr;
  overflow-x: hidden;
  overflow-y: hidden;

  a {
    max-height: min(260px, 20vw);
    width: auto;

    img {
      max-height: min(260px, 20vw);
      width: auto;
    }
  }
}

.lg-backdrop {
  backdrop-filter: blur(12px) !important;
  background-color: rgba($black, 0.6) !important;
}
