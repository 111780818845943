@use "./../../styles/variables.scss" as *;

.offer-item-point-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .item-content {
    width: fit-content;
    // white-space: nowrap;
    flex-grow: 1;
    min-width: fit-content;

    @media (max-width: map-get($grid-breakpoints, md)) {
      font-size: 14px;
    }
  }
  .item-dash {
    height: 2px;
    background-color: $primary;
    width: 100%;
    flex-grow: 1;
  }
  .item-price {
    color: $black;
    // color: $primary;
    width: fit-content;
    white-space: nowrap;
  }
}
