@use "./../../../styles/variables.scss" as *;

.responsive-frame-container {
  flex: 100%;
  max-width: 100vw;
}

.responsive-package-frame {
  &.package-frame {
    // min-width: 336px;
    width: 100%;

    @media (max-width: map-get($grid-breakpoints, sm)) {
      width: 110vw;
      margin-left: -5vw;
    }

    img {
      height: fit-content;
      user-select: none;
      pointer-events: none;
      display: block;
      // @media (max-width: map-get($grid-breakpoints, sm)) {
      //   transform: scale(0.8);
      // }
    }
    position: relative;

    .ornaments {
      position: absolute;
      max-width: 60%;
      height: 60%;
      width: auto;

      top: -2rem;
      left: -2rem;
      z-index: 1;
      user-select: none;

      @media (max-width: map-get($grid-breakpoints, xl)) {
        max-height: 40%;
        width: 40%;
        left: 0;
        height: auto;
      }
    }

    .top-frame {
      display: flex;
      position: relative;
      height: 73px;

      @media (max-width: map-get($grid-breakpoints, sm)) {
        width: 160%;
        margin-left: -30%;
        transform: scale(0.6);
        transform-origin: bottom;
      }

      .top-left {
        transform-origin: right;
        height: 100%;
        width: 98px;
        background-image: url("./../../../assets/frame-borders/responsive/left-top.png");
      }
      .top-middle-1 {
        height: 100%;
        flex-grow: 1;
        background-image: url("./../../../assets/frame-borders/responsive/middle-top.png");
        background-repeat: repeat-x;
      }
      .top-middle-2 {
        height: 100%;
        flex-grow: 1;
        background-image: url("./../../../assets/frame-borders/responsive/middle-top.png");
        background-repeat: repeat-x;
        transform: scaleX(-1);
      }
      .top-right {
        transform-origin: left;
        height: 100%;
        width: 119px;
        background-image: url("./../../../assets/frame-borders/responsive/right-top.png");
      }
    }

    .middle-frame {
      display: flex;

      .middle-left {
        height: auto;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 119px;
        background-image: url("./../../../assets/frame-borders/responsive/left-middle.png");

        @media (max-width: map-get($grid-breakpoints, sm)) {
          flex-basis: calc(119px * 0.55);
          background-size: calc(119px * 0.6) 100%;
          margin-left: 2%;
        }
      }

      .middle-middle {
        flex-grow: 1;
        width: 100%;
        box-sizing: border-box;

        .middle-title {
          width: 100%;
          text-align: center;
          padding-left: 10%;
        }
        .middle-content {
          padding-left: 10%;
        }

        @media (max-width: map-get($grid-breakpoints, xl)) {
          .middle-title {
            padding-top: 10%;
            padding-left: 10%;
          }

          .middle-content {
            padding-top: 20%;
            padding-left: 0%;
          }
        }

        @media (max-width: map-get($grid-breakpoints, sm)) {
          margin-left: -10%;

          .middle-title {
            padding-top: 0%;
            padding-left: 0%;
            width: 110%;
          }

          .middle-content {
            padding-top: 10%;
            padding-left: 0%;
            width: 100%;
            .item-content {
              white-space: normal;
              max-width: 60%;
              min-width: min-content;
            }
            .item-dash {
              // width: 20%;
              // min-width: 10%;
              width: 0%;
              opacity: 0;
            }
          }
        }
      }

      .middle-right {
        height: auto;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 119px;
        background-image: url("./../../../assets/frame-borders/responsive/right-middle.png");

        @media (max-width: map-get($grid-breakpoints, sm)) {
          flex-basis: calc(119px * 0.6);
          background-size: calc(119px * 0.6) 100%;
          padding-left: 2%;
          margin-left: -10%;
        }
      }

      @media (max-width: map-get($grid-breakpoints, lg)) {
        .middle-title {
          padding-bottom: 1rem;
        }
      }

      .package-actions {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    .bottom-frame {
      display: flex;
      position: relative;
      height: 108px;

      @media (max-width: map-get($grid-breakpoints, sm)) {
        width: 160%;
        margin-left: -30%;
        transform: scale(0.6);
        transform-origin: top;
      }

      .bottom-left {
        transform-origin: right;
        height: 100%;
        width: 119px;
        background-image: url("./../../../assets/frame-borders/responsive/left-bottom.png");
      }

      .bottom-middle-1 {
        flex-grow: 1;
        background-image: url("./../../../assets/frame-borders/responsive/middle-bottom.png");
        background-repeat: repeat-x;
      }
      .bottom-middle-2 {
        flex-grow: 1;
        background-image: url("./../../../assets/frame-borders/responsive/middle-bottom.png");
        background-repeat: repeat-x;
        transform: scaleX(-1);
      }
      .bottom-right {
        transform-origin: left;
        height: 100%;
        width: 119px;
        background-image: url("./../../../assets/frame-borders/responsive/right-bottom.png");
      }
    }
  }
}
