@use "./variables.scss" as *;
@use "./styleguides.scss" as *;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-style: normal;
  font-weight: 600;
  font-size: $font-size-base;
  background-color: $white;

  // cursor: url("./../assets/cursors/default-white.cur"), auto;
  // cursor: url("./../assets/cursors/text-white.cur"), text;
}

::-moz-selection {
  /* Code for Firefox */
  color: $white;
  background: $primary;
}

::selection {
  color: $white;
  background: $primary;
}

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  box-shadow: none;
  background: $gray;
}

body::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 12px;
  border: 3px solid $gray;
}

a,
.a-styling {
  text-decoration: none !important;
  color: $primary;
  cursor: pointer;

  &:hover {
    color: $accent;
  }
}

.link-1 {
  text-decoration: none !important;
  color: $primary;
  cursor: pointer;

  &:hover {
    color: $white;
  }
}

.reveal-on-scroll {
  animation-name: none !important;
}

.animate__animated {
  -webkit-animation-fill-mode: none !important;
  animation-fill-mode: none !important;
}

.bullet-point-left {
  padding-left: 1rem;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background-color: $primary;
    left: 0rem;
    top: 50%;
    transform: translateY(-50%);
    height: 0.4rem;
    width: 0.4rem;
    border-radius: 1rem;
  }
}

.bullet-point-right {
  padding-right: 1rem;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background-color: $primary;
    right: 0rem;
    top: 50%;
    transform: translateY(-50%);
    height: 0.4rem;
    width: 0.4rem;
    border-radius: 1rem;
  }
}

.subscribe-email-form {
  width: min(100vw, 500px);
  // width: 100%;
  box-sizing: border-box;

  input {
    box-sizing: border-box;
  }
}

.all-unset {
  all: unset;
}

button {
  all: unset;
}

.confirm-button {
  color: $dark;
  &::after {
    background-color: $white;
  }

  &:hover,
  &:active,
  &:focus-visible {
    color: $white;

    &::after {
      background-color: $dark;
    }

    &::before {
      background-color: $white;
    }
  }
}

.close-icon {
  padding: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  box-sizing: content-box;
  background: url("./../assets/icons/close-icon-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.5rem 1.5rem;
  transition: all 0.2s ease-out;
  z-index: 999;

  &:hover {
    background: url("./../assets/icons/close-icon-black.svg");
    background-size: 1.5rem 1.5rem;
    background-position: center;
    background-repeat: no-repeat;
  }

  // Preload the images
  &:before {
    position: absolute;
    z-index: -1;
    user-select: none;
    pointer-events: none;
    height: 0px;
    width: 0px;
    opacity: 0;
    background-size: 1.5rem 1.5rem;
    content: url("./../assets/icons/close-icon-white.svg") url("./../assets/icons/close-icon-black.svg");
  }
}

@keyframes closeModal {
  0% {
    margin-top: 0vh;
    opacity: 1;
    display: block;
  }
  100% {
    margin-top: 100vh;
    opacity: 0;
    display: none;
  }
}

@keyframes openModal {
  0% {
    margin-top: 100vh;
    opacity: 0;
    display: none;
  }
  100% {
    margin-top: 0vh;
    opacity: 1;
    display: block;
  }
}

.remove-after-glows {
  &::after {
    display: none !important;
  }
}

.remove-filter-glows {
  filter: none !important;
}

.ghost-over {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.section-divider-title {
  background: -webkit-linear-gradient($primary, $accent);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &::-moz-selection {
    /* Code for Firefox */
    color: $white;
    background: $primary;
    background-clip: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: $white;
  }

  &::selection {
    color: $white;
    background: $primary;
    background-clip: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: $white;
  }
}

.divider-line {
  width: 100%;
  height: 2px;
  background-color: $lt-gray;
}

.frame-container {
  margin-top: 3rem;
  position: relative;
  flex: 45%;
  width: 100%;
  max-width: 100vw;
}

.frame-replacement {
  margin-top: 3rem;
  position: relative;
  flex: 35%;
  width: 100%;
  max-width: 500px;
}

.package-title {
  font-family: "Niconne", cursive;
  font-size: clamp(38px, 3.5vw, 68px);
  font-weight: 400;
  padding: 3rem 0rem;
  box-sizing: border-box;
  background: -webkit-linear-gradient($primary, $accent);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &::-moz-selection {
    /* Code for Firefox */
    color: $white;
    background: $primary;
    background-clip: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: $white;
  }

  &::selection {
    color: $white;
    background: $primary;
    background-clip: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: $white;
  }
}

.package-description {
  font-size: clamp(12px, 3.5vw, 16px);

  // @media (max-width: map-get($grid-breakpoints, sm)) {
  //   text-align: center;
  //   .bullet-point-left {
  //     padding-left: 0px;
  //     &::before {
  //       display: none;
  //     }
  //   }
  // }
}

.package-extra {
  font-weight: 800;
}

.bullet-points-wrapper {
  display: flex;
  gap: 3rem;

  .bullet-points-column {
    // flex-basis: 1;
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }

  @media (max-width: map-get($grid-breakpoints, xl)) {
    flex-wrap: wrap;
    gap: 0.5rem;
    .bullet-points-column {
      gap: 0.5rem;
    }
  }
}

.custom-checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  padding-top: 2px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox-container input {
  position: absolute;
  opacity: 0;
  left: 0;
  cursor: pointer;
  height: 100%;
  // width: 100%;
}

/* Create a custom checkbox */
.custom-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  background: lighten($primary, 30%);
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
.custom-checkbox-container:hover input ~ .custom-checkmark {
  background-color: lighten($primary, 15%);
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox-container input:checked ~ .custom-checkmark {
  background: $primary;
}

/* Create the custom-checkmark/indicator (hidden when not checked) */
.custom-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the custom-checkmark when checked */
.custom-checkbox-container input:checked ~ .custom-checkmark:after {
  display: block;
}

/* Style the custom-checkmark/indicator */
.custom-checkbox-container .custom-checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid $white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#rcc-decline-button {
  cursor: pointer;
}
.cookie-decline-button {
  margin-right: 6rem;
}

@media (max-width: map-get($grid-breakpoints, md)) {
  .CookieConsent {
    & > div:first-of-type {
      margin: 8px !important;
    }
  }
  #rcc-confirm-button {
    width: 100vw !important;
    display: block !important;
    text-align: center !important;

    .cookie-confirm-button {
      margin-top: -0.5rem;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  #rcc-decline-button {
    width: 100vw !important;
    display: block !important;
    text-align: center !important;

    .cookie-decline-button {
      width: 100%;
      margin-right: 0rem;
    }
  }
}

.text-and-image-section {
  display: flex;
  gap: 1rem;
  width: 100%;

  .text-side {
    flex-basis: 50%;
    font-size: 1.1rem;
    flex-direction: column;
    display: flex;
    height: auto;
    justify-content: flex-start;
    gap: 1.6rem;
  }
  .image-side {
    flex-basis: 50%;
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    flex-wrap: wrap-reverse;

    .text-side,
    .image-side {
      flex-basis: 100%;
    }
  }
}

.right-side-content-image {
  border-radius: 30px;
  user-select: none;
}

.media-container {
  aspect-ratio: 16 / 9;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    aspect-ratio: 1;
  }
}

.cky-consent-container .cky-consent-bar {
  // background: rgb(255 255 255 / 20%) !important;
  // backdrop-filter: blur(12px) !important;
}
