@use "./../../../styles/variables.scss" as *;

.package-frame-2 {
  &.package-frame {
    margin-bottom: -60%;
    width: 100%;

    @media (max-width: map-get($grid-breakpoints, sm)) {
      width: 140vw;
      // margin-left: -20vw;
      left: 50%;
      transform: translate(-51%, 0%);
      margin-bottom: -90%;
    }

    img {
      height: fit-content;
      user-select: none;
      display: block;
      // pointer-events: none;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .top-frame {
      width: 100%;

      .full-top {
        height: auto;
        width: 100%;
      }
    }

    .middle-frame {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: auto;

      .full-middle-1 {
        width: 100%;
        flex-grow: 1;
        background-image: url("./../../../assets/frame-borders/frame-2/middle.png");
        background-repeat: repeat-y;
        background-size: 100%;
        background-position: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-bottom: 5rem;

        .middle-title {
          padding-top: 0px;
          padding-bottom: 1.5rem;
          width: 100%;
          width: -webkit-fill-available;
          width: -moz-available;
          text-align: center;

          @media (max-width: map-get($grid-breakpoints, sm)) {
            left: 0%;
            transform: translate(0%, 0%);
            width: 100vw;
          }
        }

        .middle-content {
          padding: 0rem 2rem;
          width: 50%;
          // margin-left: 25%;
          // position: absolute;
          // top: 55%;
          // transform: translate(50%, 0%);

          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          justify-content: center;
          align-items: center;

          @media (max-width: map-get($grid-breakpoints, sm)) {
            left: 15vw;
            transform: translate(0%, 0%);
            width: 70vw;
          }
        }
      }
    }

    .bottom-frame {
      width: 100%;
      transform: translate(0%, -60%);
      pointer-events: none;

      .full-bottom {
        height: auto;
        width: 100%;
      }
    }
  }
}
