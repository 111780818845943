@use "./../../styles/variables.scss" as *;

.button-container {
  .button {
    padding: 1.4rem 1rem;
    color: $dark;
    border-radius: 12px;
    transition: 0.2s ease-out;
    font-size: 1.4rem;
    font-weight: 700;

    &.color-primary {
      &.type-full {
        background-color: $primary;
        color: $white;

        // &:hover {
        //   background-color: $white;
        //   color: $primary;
        // }
        &:hover {
          background-color: $accent;
          color: $white;
          box-shadow: rgba($accent, 0.3) 0px 0px 32px 16px;
        }
      }

      &.type-outline {
        background-color: $white;
        border: 2px solid $primary;
        color: $primary;

        // &:hover {
        //   background-color: $primary;
        //   border: 2px solid $white;
        //   color: $white;
        // }
        &:hover {
          background-color: $white;
          border: 2px solid $accent;
          color: $accent;
          box-shadow: rgba($accent, 0.2) 0px 0px 32px 16px;
        }
      }
    }

    &.color-white {
      &.type-full {
        background-color: $white;
        color: $dark;

        &:hover {
          background-color: $dark;
          color: $white;
        }
      }

      &.type-outline {
        background-color: $white;
        border: 2px solid $dark;
        color: $dark;

        &:hover {
          background-color: $dark;
          border: 2px solid $white;
          color: $white;
        }
      }
    }

    &.color-secondary {
      &.type-full {
        background-color: $secondary;
        color: $white;

        &:hover {
          background-color: $white;
          color: $secondary;
        }
      }

      &.type-outline {
        background-color: $white;
        border: 2px solid $secondary;
        color: $secondary;

        &:hover {
          background-color: $secondary;
          border: 2px solid $white;
          color: $white;
        }
      }
    }

    &.color-accent {
      &.type-full {
        background-color: $accent;
        color: $white;

        &:hover {
          background-color: $white;
          color: $accent;
        }
      }

      &.type-outline {
        background-color: $white;
        border: 2px solid $accent;
        color: $accent;

        &:hover {
          background-color: $accent;
          border: 2px solid $white;
          color: $white;
        }
      }
    }
  }
}
