@use "./../../../styles/variables.scss" as *;

.package-frame-1 {
  &.package-frame {
    // min-width: 336px;
    width: 100%;

    // @media (max-width: map-get($grid-breakpoints, sm)) {
    //   width: 110vw;
    // }
    @media (max-width: map-get($grid-breakpoints, sm)) {
      width: 110vw;
      // margin-left: -20vw;
      left: 50%;
      transform: translate(-45%, 0%);
    }

    img {
      height: fit-content;
      user-select: none;
      pointer-events: none;
      display: block;
    }

    display: flex;
    position: relative;
    justify-content: center;

    .left-frame {
      .full-left {
        height: 100%;
        width: auto;
      }

      @media (max-width: map-get($grid-breakpoints, sm)) {
        margin-left: -10vw;
      }
    }

    .middle-frame {
      display: flex;
      flex-grow: 1;

      .full-middle-1 {
        flex-grow: 1;
        background-image: url("./../../../assets/frame-borders/frame-1/middle.png");
        background-repeat: repeat-x;
        background-size: auto 100%;
        user-select: none;
      }
      .full-middle-2 {
        flex-grow: 1;
        background-image: url("./../../../assets/frame-borders/frame-1/middle.png");
        background-repeat: repeat-x;
        background-size: auto 100%;
        user-select: none;
        transform: scaleX(-1);
      }

      .middle-title {
        position: absolute;
        top: 6rem;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        @media (max-width: map-get($grid-breakpoints, sm)) {
          left: 0%;
          transform: translate(0%, -50%);
          width: 100vw;
        }
      }

      .middle-content {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);

        display: flex;
        flex-direction: column;
        gap: 4.5rem;
        justify-content: center;
        align-items: stretch;

        @media (max-width: map-get($grid-breakpoints, sm)) {
          left: 15vw;
          transform: translate(0%, -50%);
          width: 70vw;
        }
      }
    }

    .right-frame {
      .full-right {
        height: 100%;
        width: auto;
      }
    }
  }
}
