@use "../../../styles/variables.scss" as *;

.modal-action-area {
  // width: 100%;
  left: 0;
  right: 0;

  .button {
    max-width: 150px;
  }
}

.cancel-button {
  margin-bottom: 2rem;
}

.confirm-button {
  margin-bottom: 1.7rem;
}

.close-icon {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
}
